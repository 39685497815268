


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TTextChat } from '@/_types/text-chats/text-chat.type';

const DEFAULT_AVATAR_EVENT = require('@/assets/images/no-avatar-event-60x60.svg');

@Component
export default class TextChatExpandedThumbnail extends Vue {

  // DATA

  // COMPUTED

  public get expandedChatId(): number {
    return this.$route.params.textChatId ? parseInt(this.$route.params.textChatId, 10) : null;
  }

  public get imageStyles(): {[key: string]: string} {
    let backgroundImageUrl = DEFAULT_AVATAR_EVENT;
    if (this.chatData && this.chatData.photo_url) {
      backgroundImageUrl = this.chatData.photo_url;
    }
    return {
      'background-image': 'url(' + backgroundImageUrl + ')'
    };
  }

  public get isExpanded(): boolean {
    if (!this.chatData) {
      return false;
    }
    return this.chatData.id === this.expandedChatId;
  }

  // PROPS
  @Prop({ type: Object, default: {} })
  public readonly chatData: TTextChat;

  // WATCH

  // HOOKS

  // METHODS
  private onClick(textChatId: number): void {
    this.$router.push({
      name: 'text-chat-expanded',
      params: {
        textChatId: textChatId.toFixed(0)
      }
    }).catch(() => {
      /* ignore */
    });
  }

}

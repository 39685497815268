


import { Component, Vue } from 'vue-property-decorator';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import PageTitle from '@/_components/page-title/page-title.vue';
import textChatsList from '@/_modules/text-chats/components/text-chats-list/text-chats-list.vue';

@Component({
  components: {
    SearchBar,
    PageTitle,
    textChatsList,
  }
})
export default class TextChats extends Vue {

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get expandedChatId(): number {
    return this.$route.params.textChatId ? parseInt(this.$route.params.textChatId, 10) : null;
  }

  public get contactId(): number {
    return this.$route.params.contact_id ? parseInt(this.$route.params.contact_id, 10) : null;
  }

  public get isChatColumnVisible(): boolean {
    return !!this.expandedChatId;
  }

  private onListScrollTopUpdated(data: number): void {
    try {
      const columnFirstScroll: Vue = this.$refs.columnFirstScroll as Vue;
      columnFirstScroll.$el.scrollTop = data;
    } catch (e) {
      /* ignore */
    }
  }

}
